<template>
  <div class="m1">
    <div class="hd ">
      <div class="h1">
        告警信息
      </div>
    </div>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd bg">
        <span class="hd_1" style="width: 3%; ">
          序号
        </span>
        <span class="hd_2" style="width: 15%; ">
          <div>
            项目名称
          </div>
        </span>
        <span class="hd_2" style="width: 15%; ">
          <div>
            设备名称
          </div>
        </span>
        <span class="hd_2" style="width: 18%;">
          <div>
            设备位置
          </div>
        </span>
        <span class="hd_2" style="width: 18%;">
          <div>
            异常原因
          </div>
        </span>
        <span class="hd_2" style="width: 10%;">
          <div>
            开始时间
          </div>
        </span>
        <span class="hd_2" style="width: 7%;">
          <div>
            警告时长
          </div>
        </span>
        <span class="hd_2" style="width: 10%;">
          <div>
            结束时间
          </div>
        </span>
        <span class="hd_2" style="width: 3%;">
          <div>
            操作
          </div>
        </span>
      </div>

      <!-- 主体 -->
      <div style="">
        <div class="main" v-for="(item,index) in Data" :key="index" :class="index % 2 == 0?'bg1':'bg2'">
          <div style="width: 100%;display: flex;">
            <span style="width: 100%;display: flex;" @click="xq(item)">
              <div class="m_1" style="width: 3.3%;">
                {{index + 1}}
              </div>
              <span class="m_2" style="width: 15.3%;">
                <div class="z1">
                  <div class="item-show" style="display: flex;justify-content: center;">{{item.projectName}}</div>
                  <div class="hidden-text">{{item.projectName}}</div>
                </div>
              </span>
              <span class="m_2" style="width: 15.3%;">
                <div class="z1">
                  <div class="item-show" style="display: flex;justify-content: center;">{{item.name}}</div>
                  <div class="hidden-text">{{item.name}}</div>
                </div>
              </span>
              <span class="m_2" style="width: 18.3%;">
                <div class="z1">
                  <div class="item-show" style="display: flex;justify-content: center;">{{item.address}}</div>
                  <div class="hidden-text">{{item.address}}</div>
                </div>
              </span>
              <span class="m_2" style="width: 18.3%;">
                <div class="z1">
                  <div class="item-show" style="display: flex;justify-content: center;">{{item.abnormalCause}}</div>
                  <div class="hidden-text">{{item.abnormalCause}}</div>
                </div>
              </span>
              <span class="m_2" style="width: 10.3%;">
                <div>
                  {{item.startTime}}
                </div>
              </span>
              <span class="m_2" style="width: 7.3%;">
                <div>
                  {{item.alarmDuration}}
                </div>
              </span>
              <span class="m_2" style="width: 10.5%;">
                <div>
                  {{item.endTime}}
                </div>
              </span>
            </span>
            <span class="m_2" style="width: 3.2%;">
              <div style="color: #ff0000" @click="del(item.id)">
                删除
              </div>
            </span>
          </div>
        </div>
      </div>

    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'gsgly',
    data() {
      return {
        list: [{
            name: '在线检测',
            qr: false
          },
          {
            name: '项目管理',
            qr: false
          }, {
            name: '设备管理',
            qr: false
          }, {
            name: '施工管理',
            qr: false
          }, {
            name: '告警管理',
            qr: false
          }, {
            name: '账号管理',
            qr: false
          }
        ],
        value: true,
        currentPage4: 1,
        obj: {
          "page": 1,
          "size": 10
        },
        Data: [],
        total: null
      }
    },
    created() {
      this.info()
    },
    methods: {
      xq(item) {
        this.$router.push('/gjxx1?id=' + item.equipmentId + '&type=' + item.type)
      },
      del(id) {

        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$newGet('/warning/record/delWarning/' + id).then(res => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              this.info()
            } else {
              this.$message({
                type: 'error',
                message: '删除失败'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });


      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.obj.size = val
        this.info()

      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.obj.page = val
        this.info()
      },
      info() {
        this.$ajax('gjxx', 'post', this.obj).then(res => {
          console.log(res);
          this.Data = res.data.records
          this.total = res.data.total
          // this.Data.forEach(item => {
          //   if (item.state == 0) {
          //     this.$set(item, 'KG', true)
          //   } else {
          //     this.$set(item, 'KG', false)
          //   }
          // })
        })
      }
    },

  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(188, 188, 188, 0.3);
  }

  .bg1 {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .bg2 {
    background: rgba(163, 169, 169, 0.25);
  }

  .m1 {
    position: relative;
    color: #595959;
    margin: 1rem 0 0 .2rem;
    width: 100%;

    // height: 59.4rem;

    .hd {
      position: relative;
      // width: 100%;
      width: 156.6rem;
      height: 5rem;


      .h1 {
        display: inline-block;
        width: 11.5rem;
        height: 3rem;
        line-height: 3rem;
        padding-left: 1rem;
        font-size: 1.4rem;
      }

      .h2 {
        position: absolute;
        top: 1rem;
        right: 2rem;
        width: 11.5rem;
        height: 2rem;
        // background-color: rgba(43, 201, 222, 1);
        line-height: 2rem;
        text-align: center;
        border-radius: .5rem;
      }
    }

    .lb {
      position: relative;
      // border: 1px solid #2BDEC9;
      // margin-top: 1rem;
      font-size: 0.9rem;
      // overflow: auto;

      .hd {
        width: 100%;
        height: 3.6rem;
        line-height: 3.6rem;
        // padding-left: 1rem;
        font-size: 0.9rem;
        display: flex;
        align-items: center;

        // border-bottom: 1px solid #2BDEC9;
        .hd_1 {
          display: flex;
          justify-content: center;
          width: 5%;
          height: 2rem;
          line-height: 2rem;
          text-align: center;
          margin-right: .5%;
          // background-color: rgba(43, 201, 222, 0.6);
        }

        .hd_2 {
          display: inline-block;

          height: 2rem;
          line-height: 2rem;
          // background-color: rgba(43, 201, 222, 0.6);
          margin-right: .5rem;

          display: flex;
          justify-content: center;
        }

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }
      }

      .hd:last-child {
        // border-bottom: none;
      }

      .qx {
        position: absolute;
        top: .1rem;
        left: 50rem;
        display: inline-block;

        .lst {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          background-color: rgba(43, 201, 222, 0.6);
          text-align: center;
          border-radius: .3rem;
          margin-right: 1rem;
        }
      }

      .xg {
        position: absolute;
        top: .5rem;
        right: 2rem;
        width: 27rem;
        height: 2.6rem;
        line-height: 2.6rem;

        .gg {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          text-align: center;
          background-color: rgba(28, 184, 184, 1);
          border-radius: .4rem;
          margin-left: 1rem;
        }
      }
    }

    .main {
      position: relative;
      width: 100%;
      // height: 2rem;
      // margin-top: .5rem;
      padding: .5rem 0;

      .m_1 {
        display: flex;
        width: 5%;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        margin-right: .5%;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        justify-content: center;
      }

      .m_2 {
        display: flex;
        width: 19%;
        height: 2rem;
        line-height: 2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-right: .5rem;
        justify-content: center;
      }

      .m_3 {
        position: relative;
        box-sizing: border-box;
        float: right;
        width: 94.5%;
        height: 2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-top: .5rem;
        line-height: 2rem;

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }

        .qx {
          position: absolute;
          top: .1rem;
          left: 50rem;
          display: inline-block;

          .lst {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            background-color: rgba(43, 201, 222, 0.6);
            text-align: center;
            border-radius: .3rem;
            margin-right: 1rem;
          }
        }

        .xg {
          position: absolute;
          top: .5rem;
          right: 2rem;
          width: 27rem;
          height: 2.6rem;
          line-height: 2.6rem;

          .gg {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            text-align: center;
            background-color: rgba(28, 184, 184, 1);
            border-radius: .4rem;
            margin-left: 1rem;
          }
        }
      }

      .m_3:last-child {
        margin-bottom: 1rem;
      }
    }

    .main:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .z1 {
    width: 100%;
    height: 2rem;

    .item-show {
      height: 2rem;
      line-height: 2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .hidden-text {
      font-size: 1rem;
      position: relative;

      // width: 25rem;
      line-height: 1.20rem;
      z-index: -2;
      top: -2rem;
      padding: 8px 8px 8px 8px;
      border-radius: .2rem;
    }

  }

  .z1:hover .hidden-text {
    // display: show;
    background-color: rgb(15, 162, 220);
    z-index: 999;
  }
</style>
